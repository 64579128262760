<script>
import Vue from "vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "../helper/Gen";

export default {
  extends: BaseVue,
  data() {
    return {
      data: [],
    };
  },
  computed: {},
  async mounted() {
    this.getInstitution();
    await this.ready();
  },
  methods: {
    getInstitution() {
      Gen.apirest("/institution", {}, (err, resp) => {
        if (err) console.log("Lembaga Lainnya " + err);
        this.data = resp.data;
      });
    },
  },
};
</script>
<template>
  <div>
    <section id="content">
      <div class="content-wrap">
        <section class="section pt-100">
          <div class="container">
            <div class="heading-block center border-0">
              <h2 itemprop="alternativeHeadline">Situs Referensi Belajar Resmi</h2>
            </div>
            <div class="row justify-content-center gutter-24">
              <div
                class="col-lg-3 col-md-4"
                v-for="(value, index) in data"
                :key="index"
              >
                <a itemscope itemtype="https://schema.org/EducationalOrganization" :href="value.ai_url" target="_blank" class="card-elem">
                  <div class="institution">
                    <img
                      itemprop="thumbnailUrl"
                      :src="uploader(value.ai_img)"
                      :alt="value.ai_alt_img"
                      :title="value.ai_alt_img"
                      class="institution__img"
                    />
                    <div class="institution-meta">
                      <h3 itemprop="name" class="institution-meta__title">
                        {{ value.ai_name }}
                      </h3>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
